import React, { useState, useEffect } from "react";
import "./Header.css";
import Logo_w from "../../design/ico/logo_pronalce_sa_w.png";
import Logo_sur from "../../design/ico/logo_del_sur_w.png";
import { NavLink } from "react-router-dom";

import Hojuelas from "../../design/images/Hojuelas.jpg";
import Hojuelas2 from "../../design/images/Hojuelas2.jpg";
import Harinas from "../../design/images/Harinas.jpg";
import Chocotom from "../../design/images/Chocotom.png";
import Granola from "../../design/images/Granola.jpg";
import Mezclas_listas from "../../design/images/Mezclas_listas.jpg";
import Nosotros from "../../design/ico/Planta_Pronalce2_w.png";

const whatsappUrl =
  "https://api.whatsapp.com/send?phone=573216479372&text=Hola%2C%20estoy%20interesado%20en%20comprar%20un%20producto";
const tienda = "https://pronalce.com/tienda";

function handleMouseEnter(imageUrl) {
  const header = document.querySelector('.header'); // Selecciona tu header
  header.style.backgroundImage = `url(${imageUrl})`;
}

function handleMouseLeave() {
  const header = document.querySelector('.header'); // Selecciona tu header
  header.style.backgroundImage = ''; // Restablece el fondo
}

const Header = () => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [timerId, setTimerId] = useState(null);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [isHeaderDownFixed, setIsHeaderDownFixed] = useState(false);
  const [headerBackground, setHeaderBackground] = useState(""); // Nuevo estado para el fondo del header

  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
    clearTimeout(timerId);
    if (!showSubMenu) {
      setTimerId(
        setTimeout(() => {
          setShowSubMenu(false);
        }, 10000)
      ); // 10 segundos
    }
  };

  const handleMouseEnter = (image) => {
    setCurrentImage(image);
    setHeaderBackground(`url(${image})`); // Cambia el fondo del header
    clearTimeout(timerId);
  };

  const handleMouseLeave = () => {
    setCurrentImage("");
    setHeaderBackground(""); // Restaura el fondo del header
    setTimerId(
      setTimeout(() => {
        setShowSubMenu(false);
      }, 10000)
    ); // 10 segundos
  };

  const handleClickOutside = (event) => {
    if (event.target.closest(".submenu") === null) {
      setShowSubMenu(false);
      clearTimeout(timerId);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSubMenu]);

  const handleSubMenuItemClick = () => {
    setShowSubMenu(false); // Oculta el submenú cuando se hace clic en un ítem del submenú
  };

  const [showSubMenuMobile, setShowSubMenuMobile] = useState(false);

  const toggleSubMenuMobile = () => {
    setShowSubMenuMobile(!showSubMenuMobile);
  };

  const closeSubMenuMobile = () => {
    setShowSubMenuMobile(false);
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsHeaderVisible(false);
      setIsHeaderDownFixed(true);
    } else {
      setIsHeaderVisible(true);
      setIsHeaderDownFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <header className="header">
        <div className="header-up">
          <img className="logo" src={Logo_w} alt="Pronalce" />
          <div className="mensaje-header">
            <p>Estamos listos para ser tu mejor aliado!!!</p>
          </div>
          <div className="Contactanos">
            <div className="dark-mode-toggle">
              <span className="dark-mode-text">Dark Mode</span>
              <input type="checkbox" id="dark-mode-toggle-checkbox" />
              <label
                htmlFor="dark-mode-toggle-checkbox"
                className="toggle-label"
              ></label>
            </div>
            <div>
              <NavLink to={whatsappUrl} target="_blank" onClick={handleSubMenuItemClick}>
                <button ClassName="button">
                  <i ClassName="animation"></i>{" "}
                  <i class="fa-solid fa-phone-volume fa-shake"></i> Contactanos{" "}
                  <i class="animation"></i>
                </button>
              </NavLink>
            </div>
          </div>
        </div>

        {/* Menu del sur 1*/} 
        <div className={`header-down ${isHeaderDownFixed ? "header-down-visible" : "header-down-hidden"}`}>
          <div className="down-1">
            <NavLink exact to="/">
              <span className="fa-solid fa-house-chimney"></span>
            </NavLink>
            <nav className="menu2" id="menu-2">
              <ul>
                <li>
                  <NavLink to="#" className="menusur" onClick={toggleSubMenu}>
                    
                  <i class="fa-solid fa-bars"></i>     Menu 
                  
                  </NavLink>
                  {showSubMenu && (
                    <div className="submenu">
                      <div className="submenu-1">
                        <nav className="submenu-1-item1">
                          {/* Submenú 1 */}
                          <div 
                            className="gallery-item"
                            onMouseEnter={() => handleMouseEnter(Hojuelas2)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <NavLink
                              to="/Hojuela"
                              onClick={handleSubMenuItemClick}
                            >
                              <img src={Hojuelas} alt="Hojuelas" />
                              <p>Hojuelas</p>
                            </NavLink>
                          </div>

                          <div
                            className="gallery-item"
                            onMouseEnter={() => handleMouseEnter(Harinas)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <NavLink
                              to="/Harinas"
                              onClick={handleSubMenuItemClick}
                            >
                              <img src={Harinas} alt="Harinas" />
                              <p>Harinas</p>
                            </NavLink>
                          </div>

                          <div
                            className="gallery-item"
                            onMouseEnter={() => handleMouseEnter(Chocotom)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <NavLink
                              to="/bebidas"
                              onClick={handleSubMenuItemClick}
                            >
                              <img src={Chocotom} alt="Chocotom" />
                              <p>Bebidas</p>
                            </NavLink>
                          </div>

                          <div
                            className="gallery-item"
                            onMouseEnter={() => handleMouseEnter(Granola)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <NavLink
                              to="/Granolas"
                              onClick={handleSubMenuItemClick}
                            >
                              <img src={Granola} alt="Granola" />
                              <p>Granola</p>
                            </NavLink>
                          </div>

                          <div
                            className="gallery-item"
                            onMouseEnter={() =>
                              handleMouseEnter(Mezclas_listas)
                            }
                            onMouseLeave={handleMouseLeave}
                          >
                            <NavLink
                              to="/Mezclas_listas"
                              onClick={handleSubMenuItemClick}
                            >
                              <img src={Mezclas_listas} alt="Mezclas Listas" />
                              <p>Mezclas Listas</p>
                            </NavLink>
                          </div>
                        </nav>
                      </div>
                      <div className="submenu-2">
                        {/* Submenú 2 */}
                        <div>
                          <NavLink
                            to="/Nosotros"
                            onClick={handleSubMenuItemClick}
                          >
                            <button className="btn-nosotros">
                              Recetas <i className="fa-solid fa-utensils"></i>
                            </button>
                          </NavLink>
                        </div>
                        <div></div>
                        <div>
                          {/* Agrega más elementos de submenú aquí si es necesario */}
                        </div>
                      </div>
                    </div>
                  )}
                </li>
              </ul>
            </nav>
          </div>

          <div className="donw-2">
            <ul className="list-pro">
              <li className="list-group-item">
                {" "}
                <NavLink to="/maquila">Maquila</NavLink>
              </li>
              <li className="list-group-item">
                {" "}
                <NavLink to="/Exportaciones">Exportaciones</NavLink>
              </li>
              <li className="list-group-item">
                {" "}
                <NavLink to="/Industria">Industria</NavLink>
              </li>
              <li className="list-group-item">
                {" "}
                <NavLink to="/Nosotros">
                  Nosotros <img src={Nosotros} alt="" />
                </NavLink>
              </li>
              <li className="list-group-item">
                {" "}
                <NavLink to="/TrabajaConNosotros">
                Trabaja con Nosotros
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="donw-3">
            <div className="social">
              {/* Enlaces sociales */}
              <NavLink
                to="https://www.facebook.com/pronalce"
                target="_blank"
                className="icon-facebook"
              >
                <span className="fab fa-facebook" id="logo-facebook"></span>
              </NavLink>
              <NavLink
                to="https://www.instagram.com/pronalce/"
                target="_blank"
                className="icon-instagram"
              >
                <span className="fab fa-instagram" id="logo-instagram"></span>
              </NavLink>
              <NavLink
                to="https://www.linkedin.com/in/pronalce-s-a-43262a59/"
                target="_blank"
                className="icon-linkedin"
              >
                <span
                  className="fa-brands fa-linkedin"
                  id="logo-linkedin"
                ></span>
              </NavLink>
            </div>
            <div className="list-shop">
              <NavLink to={tienda}>
                <span
                  className="fa-solid fa-cart-shopping fa-bounce"
                  id="logo_tienda"
                >
                  <p>Tienda</p>
                </span>
              </NavLink>
            </div>
          </div>
        </div>
        


      </header>



      <header>
        <div className="header-mobile">
          <div className="logo-mobile">
            <span
              className={`fa-solid ${
                showSubMenuMobile ? "fa-xmark" : "fa-bars"
              }`}
              onClick={toggleSubMenuMobile}
            ></span>{" "}
          </div>

          <img src={Logo_sur} alt="Pronalce" />

          <NavLink to={tienda}>
            <span className="fa-solid fa-basket-shopping fa-bounce"></span>
          </NavLink>
        </div>
        {showSubMenuMobile && (
          <div className="subheader" onClick={closeSubMenuMobile}>
            <ul>
              <li>
                <NavLink className="list" to="/">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink className="list" to="/maquila">
                  Maquila
                </NavLink>
              </li>
              <li>
                <NavLink className="list" to="/Exportaciones">
                  Exportaciones
                </NavLink>
              </li>
              <li>
                <NavLink className="list" to="/Industria">
                  Industria
                </NavLink>
              </li>
              <li>
                <NavLink className="list" to="/Hojuela">
                  Hojuelas
                </NavLink>
              </li>
              <li>
                <NavLink className="list" to="/Harinas">
                  Harinas
                </NavLink>
              </li>
              <li>
                <NavLink className="list" to="/Bebidas">
                  Bebidas
                </NavLink>
              </li>
              <li>
                <NavLink className="list" to="/Mezclas_listas">
                  Mezclas Listas
                </NavLink>
              </li>
              <li>
                <NavLink className="list" to="/Granolas">
                  Granolas
                </NavLink>
              </li>
              <li>
                <NavLink className="list" to="/Nosotros">
                  Nosotros
                </NavLink>
              </li>
            </ul>
            <div className="social">
              {/* Enlaces sociales */}
              <NavLink
                to="https://www.facebook.com/pronalce"
                target="_blank"
                className="icon-facebook"
              >
                <span className="fab fa-facebook" id="logo-facebook"></span>
              </NavLink>

              <NavLink
                to="https://www.instagram.com/pronalce/"
                target="_blank"
                className="icon-instagram"
              >
                <span className="fab fa-instagram" id="logo-instagram"></span>
              </NavLink>

              <NavLink
                to="https://www.linkedin.com/in/pronalce-s-a-43262a59/"
                target="_blank"
                className="icon-linkedin"
              >
                <span
                  className="fa-brands fa-linkedin"
                  id="logo-linkedin"
                ></span>
              </NavLink>
              
            </div>
          </div>
        )}
      </header>


    </div>
  );
};

export default Header;
