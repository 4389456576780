import React from "react";
import "./LineaEtica.css";

/**
 * Componente que renderiza la página de Línea Ética
 *
 * Este componente renderiza una página que contiene información sobre la Línea
 * Ética de la empresa, incluyendo una descripción, los tipos de situaciones que
 * pueden ser reportadas y los medios para comunicarse.
 *
 * @return {ReactElement} Un ReactElement que representa la página de Línea Ética
 */
const LineaEtica = () => {
  return (
    <div className="linea-etica-container">
      <div className="Header_linea-etica"></div>

      <main className="main-content-etica">
        <h1 className="title-etica">Línea Ética</h1>
        <h1>¿Qué es la Línea Ética?</h1>
        <p>
          <b>La Línea Ética de Pronalce</b> es un mecanismo diseñado para atender
          reportes de actividades contrarias a la Ley y al Código del Buen
          Gobierno, así como conductas que atenten contra los derechos humanos o
          comportamientos inapropiados e indeseables dentro de nuestra
          organización.
        </p>

        <p>
          Cualquier colaborador(a) o tercero(a) relacionado(a) con  <b>Pronalce </b> 
          puede realizar un reporte a través de nuestra Línea Ética,
          contribuyendo a mantener una cultura organizacional basada en la
          transparencia, el respeto y la legalidad.
        </p>

        <div className="info-section">
          <div>
            <h2> </h2>
            <p>
            En <b> Pronalce</b>, nuestros valores y principios son fundamentales para garantizar una cultura organizacional basada en la ética, la transparencia y el respeto. A través de la Línea Ética, ofrecemos un espacio seguro y confidencial donde cualquier colaborador(a), proveedor(a), cliente o tercero(a) relacionado(a) con la empresa puede reportar situaciones o conductas que puedan afectar negativamente estos pilares esenciales.
            </p>
          </div>

          <div>
            <h2>¿Cómo comunicarse?</h2>
            <p>
              Teléfono: <a href="tel:+573008744047">3008744047</a>
              <br />
              Email:{" "}
              <a href="mailto:LineaEtica@Pronalce.com.co">
                LineaEtica@Pronalce.com.co
              </a>
            </p>
            <p>Puedes reportar  a partir del siguiente formulario: </p>
            <button
              onClick={() =>
                window.open("https://forms.office.com/r/rRaLy0rAQk", "_blank")
              }
              style={{
                backgroundColor: "#73833d", // Color principal de tu empresa
                color: "#fff",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "16px",
              }}
            >
              Ir al formulario
            </button>
          </div>
        </div>

        <div className="reportable-cases">
          <h2>Ejemplos de algunos casos reportables:</h2>
          <div className="cases-grid">
            <div>Actividades que atenten contra los derechos humanos.</div>
            <div>Comportamientos inapropiados o indeseables dentro de Pronalce S.A.</div>
            <div>Participación en actividades contrarias a la ley.</div>
            <div>Uso indebido de información confidencial.</div>
            <div>Apropiación indebida de bienes.</div>
            <div>Prácticas comerciales en contra de intereses.</div>
            <div>Notificación de sospechas de lavado de dinero.</div>
            <div>Situaciones de deslealtad con la organización.</div>
            <div>Falsificación de contratos o registros.</div>
            <div>Conflicto de intereses.</div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default LineaEtica;
