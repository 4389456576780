import React from 'react';
import {Slideshow, Slide, TextoSlide} from './Slideshow'
import './estilos.css';
import styled from 'styled-components';
import img1 from '../../../design/images/Logos_clientes_1.webp';
import img2 from '../../../design/images/Logos_clientes_2.webp';
import img3 from '../../../design/images/Logos_clientes_3.webp';
import img4 from '../../../design/images/Logos_clientes_4.webp';
import img5 from '../../../design/images/Logos_clientes_5.webp';
import img6 from '../../../design/images/Logos_clientes_6.webp';
import img7 from '../../../design/images/Logos_clientes_7.webp';
import img8 from '../../../design/images/Logos_clientes_8.webp';
import img9 from '../../../design/images/Logos_clientes_9.webp';
import img10 from '../../../design/images/Logos_clientes_10.webp';
import img11 from '../../../design/images/adexa.png';
import img12 from '../../../design/images/Alpina.webp';


const Slider = () => {
	return (
		<main className="NuestrosClientes">
			

			<Titulo>Nuestros Aliados</Titulo>
			<Slideshow controles={true} autoplay={true} velocidad="3000" intervalo="5000">
				<Slide>
					
						<img src={img1} alt="Alimentos Carnicos"/>
				
				</Slide>
				<Slide>
					
						<img src={img2} alt="Alkosto"/>
					
				</Slide>
				<Slide>
					
						<img src={img3} alt="Bimbo"/>
				
				</Slide>
				<Slide>
					
						<img src={img4} alt="Nacional de Chocolates"/>
					
				</Slide>
				<Slide>
					
						<img src={img5} alt="Colanta"/>
					
				</Slide>
				<Slide>
					
						<img src={img6} alt="Colsubsidio"/>
					
				</Slide>
				<Slide>
					
						<img src={img7} alt="éxito"/>
					
				</Slide>
				<Slide>
					
						<img src={img8} alt="D1"/>
					
				</Slide>
				<Slide>
					
						<img src={img9} alt="Nutresa"/>
					
				</Slide>
				<Slide>
					
						<img src={img10} alt="Tropi"/>
					
				</Slide>
				<Slide>
					
						<img src={img11} alt="edexa"/>
					
				</Slide>
				<Slide>
					
						<img src={img12} alt="Alpina"/>
				
				</Slide>
			</Slideshow>
		</main>
	);
}

const Titulo = styled.p`
	font-size: 35px;
	width: 100%;
	color:#7b8a3e;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 10px;
`;
 
export default Slider;