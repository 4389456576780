import React from 'react';
import "./Footer.css";
import { NavLink } from "react-router-dom";


import Logo_sur_w from "../../design/ico/logo_del_sur_w.png";
import Logo_choco from "../../design/ico/logo_chocotom.png";
import Logo_co from "../../design/ico/logo_co.png";
import Logo_pronalce_w from "../../design/ico/logo_pronalce_sa_w.png";
import planta from "../../design/ico/Planta_Pronalce2.png";
import Popup from './popUP';
const tienda = "http://pronalce.myshopify.com/";
const whatsappUrl =
  "https://api.whatsapp.com/send?phone=573216479372&text=Hola%2C%20estoy%20interesado%20en%20comprar%20un%20producto";


const Footer = () => {
  return (
    <div>
      <div className='footer'>
        <footer className="footer_area">
            <div className="items-Marcas">
                <div className="forma-icono" id="icono_sur-footer">
                    <img src={Logo_sur_w} alt="del sur" />
                </div>
                <span className="linea"></span>
                <div className="forma-icono" id="icono_chocotom-footer">
                    <img src={Logo_choco} alt="chocotom" />
                </div>
            </div>
            <div className="items-Negocio">
                <div>
                    <ul className="list-unstyled f_list">
                        <li><NavLink to="maquila">Maquila</NavLink></li>
                        <li><NavLink to='exportaciones'>Exportaciones</NavLink></li>
                        <li><NavLink to='industria'>Negocio Industrial</NavLink></li>
                        
                    </ul>
                    <div className="forma-icono_co">
                        <img className="Icono_co" src={Logo_co} alt="co colombia" />
                    </div>
                </div>
            </div>
            <div className="items-Informacion">
                <div className="logo-pronal"></div>
                <img src={Logo_pronalce_w} alt="" />
                <ul>
                    <li className="linea_informacion">
                        <br />
                        <h1>Procesadora Nacional de Cereales S.A.</h1>
                        <h1>Itagüi - Antioquia - Colombia</h1>
                        <NavLink to="https://goo.gl/maps/L2LxfkM5YRifZXiDA"><h1>Carrera 50A # 43 - 13 Bodega 103</h1></NavLink>
                        <NavLink to="tel:6043720874"><h1>(604) 372 08 74</h1> </NavLink>
                        <h1>Nit: 811022715-4</h1>
                        <br />
                        <li><NavLink to='LineaEtica'>Línea Ética</NavLink></li>
                        <li><NavLink to='politicas'>Politicas Integrales</NavLink></li>
                        
                    </li>
                </ul>
            </div>
            <div className="items-Ubicación">
                <ul className="list-unstyled f_list">
                    <li><NavLink to={tienda}>Tienda</NavLink></li>
                    <li><NavLink to={tienda}>Portafolio</NavLink></li>
                    <li><NavLink to='nosotros'>Sentido Social</NavLink></li>
                    <li><NavLink to='TrabajaConNosotros'>Trabaja con Nosotros</NavLink></li>  
                </ul>
                <div className="forma-icono_sgs">
                  <img src='desing/ico/Planta_Pronalce2.png' alt="" />{' '}
                    <img  src='desing/ico/Planta_Pronalce2.png' alt="" /> {' '}
                </div>
            </div>
        </footer>
      </div>
      <div className="copyright">
        <hr />
        <p>
            <img src={planta} alt="" /> Todos los derechos reservados{' '}
            <i className="fa-regular fa-copyright"></i> 2024 <b>PRONALCE S.A</b>
        </p>
      </div>
    
        <NavLink to={whatsappUrl} className="btn-wsp" target="_blank">
            <i className="fa fa-whatsapp icono"></i>
        </NavLink>

        <Popup/>

    </div>
  );
}
export default Footer;
