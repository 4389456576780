import React, { useState, useEffect } from "react";
import "./Industria.css";
import img_avena from "../../../design/industria/Avena.webp";
import img_arroz from "../../../design/industria/Arroz.webp";
import img_cebada from "../../../design/industria/Cebada.webp";
import img_cebada_industria from "../../../design/industria/industria_costales.png";
import img_maiz from "../../../design/industria/Maiz.webp";
import img_trigo from "../../../design/industria/Trigo.webp";
import img_sobrecopas from "../../../design/industria/Cebada_Cerveza.webp";
import img_granolas from "../../../design/industria/Granolas.webp";
import img_cebada_trigo from "../../../design/industria/Cebada.webp";
import img_productos from "../../../design/industria/Productos.png";

const images = [
  {
    src: img_avena,
    alt: "Avena",
    label: "Avena",
    description: [
      "Hojuelas x 25 Kg",
      "Harina x 25 Kg",
      "Hojuelas Cortada x 25 Kg",
      "Hojuelas Granolera Gruesa x 25 Kg",
      "Hojuelas Granolera x 25 Kg",
      "Hojuelas Gluten-Free x 25 Kg",
      "Hojuelas Triturada saco x 25 Kg"
    ]
  },
  {
    src: img_maiz,
    alt: "Maíz Amarillo",
    label: "Maíz",
    description: [
      "Harina x 25 Kg",
      "Hojuelas Triturada x 25 Kg"
    ]
  },
  {
    src: img_cebada_industria,
    alt: "Cebada Industrial",
    label: "Cebada Industrial",
    description: [
      "Harina x 25 Kg",
      "Hojuelas saco x 25 Kg"
    ]
  },
  {
    src: img_trigo,
    alt: "Trigo",
    label: "Trigo",
    description: [
      "Harina saco x 25 Kg",
      "Hojuelas saco x 25 Kg"
    ]
  },
  {
    src: img_cebada_trigo,
    alt: "Cebada con Trigo",
    label: "Cebada con Trigo",
    description: [
      "Hojuelas x 20 Kg"
    ]
  },
  {
    src: img_arroz,
    alt: "Arroz",
    label: "Arroz",
    description: [
      "Arroz en Harina x 25 Kg"
    ]
  },
  {
    src: img_sobrecopas,
    alt: "Cebada para cerveza",
    label: "Cebada para cerveza",
    description: [
      "Cebada para procesos artesanales"
    ]
  },
  {
    src: img_granolas,
    alt: "Granolas",
    label: "Granolas",
    description: [
      "Los sabores que quieras crear",
      "Frutos Rojos, coco, Manzana Canela, Chocolate, Etc."
    ]
  }
];

const IndustriaPage = () => {
  const [centralImage, setCentralImage] = useState(img_avena);
  const [selectedItem, setSelectedItem] = useState(images[0]);

  useEffect(() => {
    const radius = 200; // Radio para el círculo
    images.forEach((_, index) => {
      const angle = (index * 360) / images.length;
      const x = radius * Math.cos((angle * Math.PI) / 180);
      const y = radius * Math.sin((angle * Math.PI) / 180);
      document.querySelector(`.circle-item-${index}`).style.transform = `translate(${x}px, ${y}px)`;
    });
  }, []);

  const handleClick = (src, item) => {
    setCentralImage(src);
    setSelectedItem(item);
  };

  return (
    <div className="industria-container">
      <div className="industria-header">
        <span className="palito"></span>
        <h2>
          Para la <br />
          <span className="highlight">Industria</span>
        </h2>
      </div>
      <div className="industria-content">
        <div className="txt_industria">
        <p>
            Nuestros productos son obtenidos de la selección, limpieza, cocción,
            laminación y molienda del grano integral.
          </p>
          <p>
            En nuestra empresa, nos enorgullece ofrecer soluciones innovadoras y
            de alta calidad para la industria alimentaria. Producimos y
            comercializamos productos naturales a base de cereales como avena,
            cebada, trigo, maíz, arroz y quinua, procesados para obtener hojuelas
            y harina de la más alta calidad.
          </p>
          <p>
            Además, ofrecemos una amplia variedad de mezclas de cereales ideales
            para una variedad de productos, desde granolas hasta mezclas para
            avenas saborizadas, mezclas para pancakes, mezclas de hojuelas con
            frutas y mezclas para galletas. Todos nuestros productos están
            diseñados para satisfacer las necesidades de la industria alimentaria
            y ayudar a nuestros clientes a destacarse en un mercado cada vez más
            competitivo.
          </p>
       
        </div>

        {/* Galería Circular y Recuadro de Producto */}
        <div className="circular-gallery-container">

          <div className="description-box">
            <h3>{selectedItem ? selectedItem.label : "Seleccione un grano"}</h3>
            <ul>
              {selectedItem ? 
                selectedItem.description.map((item, index) => (
                  <li key={index}><a href="https://pronalce.myshopify.com/collections/industriales">{item}</a></li>
                )) 
                : 
                <li>Desliza el cursor sobre un grano para ver detalles.</li>
              }
            </ul>
          </div>

          <div className="circular-gallery">
            <div className="central-image">
              <img src={centralImage} alt="Imagen del grano" />
            </div>
            {images.map((image, index) => (
              <div
                key={index}
                className={`circle-item circle-item-${index}`}
                onClick={() => handleClick(image.src, image)}
              >
                <img src={image.src} alt={image.alt} />
                <p>{image.label}</p>
              </div>
            ))}
          </div>

        </div>
      </div>

      <div className="product-line">
        <div className="product-line-image">
          <img src={img_productos} alt="Línea de productos" />
        </div>
      </div>
    </div>
  );
};

export default IndustriaPage;
